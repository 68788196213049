var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-step", {
          attrs: {
            stepperGrpCd: "IBM_STEP_CD",
            currentStepCd: _vm.impr.ibmStepCd,
            disabled: true,
          },
          model: {
            value: _vm.impr.ibmStepCd,
            callback: function ($$v) {
              _vm.$set(_vm.impr, "ibmStepCd", $$v)
            },
            expression: "impr.ibmStepCd",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "q-form",
          { ref: "editForm1" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "요청&접수 상세" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.requestBtnEditable,
                              expression: "requestBtnEditable",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isRequest,
                            url: _vm.saveUrl,
                            param: _vm.impr,
                            mappingType: "PUT",
                            label: "조치부서에 요청",
                            icon: "send",
                          },
                          on: {
                            beforeAction: _vm.requestImpr,
                            btnCallback: _vm.requestCallback,
                          },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.receiptBtnEditable,
                              expression: "receiptBtnEditable",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isReceipt,
                            url: _vm.saveUrl,
                            param: _vm.impr,
                            mappingType: "PUT",
                            label: "접수",
                            icon: "directions_run",
                          },
                          on: {
                            beforeAction: _vm.receiptImpr,
                            btnCallback: _vm.receiptCallback,
                          },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.saveBtnEditable1,
                              expression: "saveBtnEditable1",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isSaveRequestReceipt,
                            url: _vm.saveUrl,
                            param: _vm.impr,
                            mappingType: "PUT",
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveRequestReceiptImpr,
                            btnCallback: _vm.saveRequestReceiptCallback,
                          },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.requestCancelBtnEditable,
                              expression: "requestCancelBtnEditable",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isReject,
                            url: _vm.saveUrl,
                            param: _vm.impr,
                            mappingType: "PUT",
                            label: "요청취소",
                            icon: "delete_forever",
                          },
                          on: {
                            beforeAction: _vm.rejectImpr,
                            btnCallback: _vm.rejectCallback,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          codeGroupCd: "IBM_TASK_TYPE_CD",
                          type: "edit",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "ibmTaskTypeCd",
                          label: "업무",
                        },
                        model: {
                          value: _vm.impr.ibmTaskTypeCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "ibmTaskTypeCd", $$v)
                          },
                          expression: "impr.ibmTaskTypeCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: true,
                          label: "요청부서/이름/일자",
                          name: "request",
                          value: _vm.request,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.requestAreaEditable,
                          required: true,
                          label: "제목",
                          name: "ibmTitle",
                        },
                        model: {
                          value: _vm.impr.ibmTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "ibmTitle", $$v)
                          },
                          expression: "impr.ibmTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.requestAreaEditable,
                          required: true,
                          label: "요청내용",
                          name: "improveRequestContents",
                        },
                        model: {
                          value: _vm.impr.improveRequestContents,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "improveRequestContents", $$v)
                          },
                          expression: "impr.improveRequestContents",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-dept", {
                        attrs: {
                          editable:
                            _vm.requestAreaEditable ||
                            _vm.actionReceiptAreaEditable,
                          required: true,
                          type: "edit",
                          label: "조치부서",
                          name: "actionDeptCd",
                        },
                        model: {
                          value: _vm.impr.actionDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "actionDeptCd", $$v)
                          },
                          expression: "impr.actionDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          editable: _vm.requestAreaEditable,
                          required: true,
                          label: "조치완료 요청일",
                          name: "actionCompleteRequestDate",
                        },
                        model: {
                          value: _vm.impr.actionCompleteRequestDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "actionCompleteRequestDate", $$v)
                          },
                          expression: "impr.actionCompleteRequestDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-field", {
                        attrs: {
                          editable:
                            _vm.requestAreaEditable ||
                            _vm.actionReceiptAreaEditable,
                          type: "dept_user",
                          data: _vm.impr,
                          deptValue: "actionCompleteCheckDeptCd",
                          label: "조치완료 확인자",
                          name: "actionCompleteCheckUserId",
                        },
                        model: {
                          value: _vm.impr.actionCompleteCheckUserId,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "actionCompleteCheckUserId", $$v)
                          },
                          expression: "impr.actionCompleteCheckUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          editable: _vm.requestAreaEditable,
                          required: true,
                          type: "edit",
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.impr.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "plantCd", $$v)
                          },
                          expression: "impr.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.actionReceiptAreaEditable,
                          label: "요청취소 사유",
                          name: "requestRejectReason",
                        },
                        model: {
                          value: _vm.impr.requestRejectReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.impr, "requestRejectReason", $$v)
                          },
                          expression: "impr.requestRejectReason",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "q-form",
                { ref: "editForm2" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "조치상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.actionCompleteBtnEditable,
                                    expression: "actionCompleteBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isActionComplete,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "조치완료",
                                  icon: "directions_run",
                                },
                                on: {
                                  beforeAction: _vm.actionCompleteImpr,
                                  btnCallback: _vm.actionCompleteCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.saveBtnEditable2,
                                    expression: "saveBtnEditable2",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isSaveAction,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveActionImpr,
                                  btnCallback: _vm.saveActionCallback,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                required: true,
                                label: "조치완료일",
                                name: "actionCompleteDate",
                              },
                              model: {
                                value: _vm.impr.actionCompleteDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionCompleteDate", $$v)
                                },
                                expression: "impr.actionCompleteDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                required: true,
                                label: "검토완료 요청일",
                                name: "reviewCompleteRequestDate",
                              },
                              model: {
                                value: _vm.impr.reviewCompleteRequestDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.impr,
                                    "reviewCompleteRequestDate",
                                    $$v
                                  )
                                },
                                expression: "impr.reviewCompleteRequestDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                required: true,
                                label: "조치내용",
                                name: "actionContents",
                              },
                              model: {
                                value: _vm.impr.actionContents,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "actionContents", $$v)
                                },
                                expression: "impr.actionContents",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-upload", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                label: "개선 전 사진",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-upload", {
                              attrs: {
                                editable: _vm.actionAreaEditable,
                                label: "개선 후 사진",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "q-form",
                { ref: "editForm3" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "검토상세" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.rejectBtnEditable,
                                    expression: "rejectBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isReturn,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "반려",
                                  icon: "front_hand",
                                },
                                on: {
                                  beforeAction: _vm.returnImpr,
                                  btnCallback: _vm.returnCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.completeBtnEditable,
                                    expression: "completeBtnEditable",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isComplete,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "개선완료",
                                  icon: "check_circle_outline",
                                },
                                on: {
                                  beforeAction: _vm.completeImpr,
                                  btnCallback: _vm.completeCallback,
                                },
                              }),
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.saveBtnEditable3,
                                    expression: "saveBtnEditable3",
                                  },
                                ],
                                attrs: {
                                  isSubmit: _vm.isSaveCheck,
                                  url: _vm.saveUrl,
                                  param: _vm.impr,
                                  mappingType: "PUT",
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveCheckImpr,
                                  btnCallback: _vm.saveCheckCallback,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.requestCheckAreaEditable,
                                required: true,
                                label: "검토내용",
                                name: "reviewContents",
                              },
                              model: {
                                value: _vm.impr.reviewContents,
                                callback: function ($$v) {
                                  _vm.$set(_vm.impr, "reviewContents", $$v)
                                },
                                expression: "impr.reviewContents",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }