<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-step
        stepperGrpCd="IBM_STEP_CD"
        :currentStepCd="impr.ibmStepCd"
        :disabled="true"
        v-model="impr.ibmStepCd">
      </c-step>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm1">
        <c-card title="요청&접수 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn
                v-show="requestBtnEditable"
                :isSubmit="isRequest"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="조치부서에 요청"
                icon="send"
                @beforeAction="requestImpr"
                @btnCallback="requestCallback" />
              <c-btn
                v-show="receiptBtnEditable"
                :isSubmit="isReceipt"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="접수"
                icon="directions_run"
                @beforeAction="receiptImpr"
                @btnCallback="receiptCallback" />
              <c-btn
                v-show="saveBtnEditable1"
                :isSubmit="isSaveRequestReceipt"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveRequestReceiptImpr"
                @btnCallback="saveRequestReceiptCallback" />
              <c-btn
                v-show="requestCancelBtnEditable"
                :isSubmit="isReject"
                :url="saveUrl"
                :param="impr"
                mappingType="PUT"
                label="요청취소"
                icon="delete_forever"
                @beforeAction="rejectImpr"
                @btnCallback="rejectCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-select
                :disabled="true"
                :editable="editable"
                codeGroupCd="IBM_TASK_TYPE_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="ibmTaskTypeCd"
                label="업무"
                v-model="impr.ibmTaskTypeCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                :disabled="true"
                label="요청부서/이름/일자"
                name="request"
                :value="request">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-text
                :editable="requestAreaEditable"
                :required="true"
                label="제목"
                name="ibmTitle"
                v-model="impr.ibmTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="requestAreaEditable"
                :required="true"
                label="요청내용"
                name="improveRequestContents"
                v-model="impr.improveRequestContents">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-dept
                :editable="requestAreaEditable||actionReceiptAreaEditable"
                :required="true"
                type="edit"
                label="조치부서"
                name="actionDeptCd"
                v-model="impr.actionDeptCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                :editable="requestAreaEditable"
                :required="true"
                label="조치완료 요청일"
                name="actionCompleteRequestDate"
                v-model="impr.actionCompleteRequestDate"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-field
                :editable="requestAreaEditable||actionReceiptAreaEditable"
                type="dept_user"
                :data="impr"
                deptValue="actionCompleteCheckDeptCd"
                label="조치완료 확인자"
                name="actionCompleteCheckUserId"
                v-model="impr.actionCompleteCheckUserId" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-plant
                :editable="requestAreaEditable"
                :required="true"
                type="edit"
                name="plantCd"
                v-model="impr.plantCd" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="actionReceiptAreaEditable"
                label="요청취소 사유"
                name="requestRejectReason"
                v-model="impr.requestRejectReason">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-form ref="editForm2" >
            <c-card title="조치상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn
                    v-show="actionCompleteBtnEditable"
                    :isSubmit="isActionComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="조치완료"
                    icon="directions_run"
                    @beforeAction="actionCompleteImpr"
                    @btnCallback="actionCompleteCallback" />
                  <c-btn
                    v-show="saveBtnEditable2"
                    :isSubmit="isSaveAction"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장"
                    icon="save"
                    @beforeAction="saveActionImpr"
                    @btnCallback="saveActionCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치완료일"
                    name="actionCompleteDate"
                    v-model="impr.actionCompleteDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-datepicker
                    :editable="actionAreaEditable"
                    :required="true"
                    label="검토완료 요청일"
                    name="reviewCompleteRequestDate"
                    v-model="impr.reviewCompleteRequestDate"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <c-textarea
                    :editable="actionAreaEditable"
                    :required="true"
                    label="조치내용"
                    name="actionContents"
                    v-model="impr.actionContents">
                  </c-textarea>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-upload
                    :editable="actionAreaEditable"
                    label="개선 전 사진">
                  </c-upload>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <c-upload
                    :editable="actionAreaEditable"
                    label="개선 후 사진">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <q-form ref="editForm3">
            <c-card title="검토상세" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn
                    v-show="rejectBtnEditable"
                    :isSubmit="isReturn"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="반려"
                    icon="front_hand"
                    @beforeAction="returnImpr"
                    @btnCallback="returnCallback" />
                  <c-btn
                    v-show="completeBtnEditable"
                    :isSubmit="isComplete"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="개선완료"
                    icon="check_circle_outline"
                    @beforeAction="completeImpr"
                    @btnCallback="completeCallback" />
                  <c-btn
                    v-show="saveBtnEditable3"
                    :isSubmit="isSaveCheck"
                    :url="saveUrl"
                    :param="impr"
                    mappingType="PUT"
                    label="저장"
                    icon="save"
                    @beforeAction="saveCheckImpr"
                    @btnCallback="saveCheckCallback" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <c-textarea
                    :editable="requestCheckAreaEditable"
                    :required="true"
                    label="검토내용"
                    name="reviewContents"
                    v-model="impr.reviewContents">
                  </c-textarea>
                </div>
              </template>
            </c-card>
          </q-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          sopImprovementId: '',
          ibmTaskTypeCd: '',
          data: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      impr: {
        sopImprovementId: '',  // 개선 일련번호
        plantCd: '',  // 사업장 코드
        ibmTaskTypeCd: '',  // 업무분류코드
        ibmTitle: '',  // 제목
        request: '',
        improveRequestDeptCd: '',  // 개선요청부서
        improveRequestDeptName: '', // 개선요청부서명
        improveRequestUserId: '',  // 개선요청자
        improveRequestUserName: '',  // 개선요청자명
        improveRequestContents: '',  // 개선요청내용
        ibmStepCd: '',  // 개선진행단계
        relationTableKey: '',  // 관련테이블 키
        relationVendor: '',  // 관련업체_기술
        actionScheduleDate: '',  // 조치예정일
        actionCompleteRequestDate: '',  // 조치완료 요청일
        reviewCompleteRequestDate: '',  // 검토완료 요청일
        actionCompleteDate: '',  // 조치완료일
        actionDeptCd: '',  // 조치부서_조치부서는 변경가능
        actionContents: '',  // 조치내용_조치부서에서
        reviewContents: '',  // 요청부서 조치결과 검토_요청부서에서
        actionCompleteCheckUserId: '',  // 조치완료 확인자_부서장 또는 공장장
        actionCompleteCheckFlag: '',  // 조치완료 확인자 확인 여부
        requestRejectReason: '', // 요청취소사유
        regUserId: '',  // 등록자 ID
        regDt: '',
        chgUserId: '',  // 수정자 ID
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSaveRequestReceipt: false, // 요청&접수 저장 flag
      isRequest: false, // 요청 flag
      isReceipt: false, // 접수 flag
      isReject: false, // 요청취소 flag
      isActionComplete: false, // 조치완료 flag
      isSaveAction: false, // 조치 저장 flag
      isSaveCheck: false, // 검토 저장 flag
      isComplete: false, // 개선완료 flag
      isReturn: false, // 조치반려 flag
    };
  },
  computed: {
    request() {
      return this.impr.improveRequestDeptName + ' / ' + this.impr.improveRequestUserName + ' / '  + this.impr.regDt
    },
    disabled() {
      return this.popupParam.isSearch || this.impr.ibmStepCd === 'IS00000020';
    },
    imprEditable() {
      return this.editable && !this.disabled
    },
    requestAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    actionReceiptAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    actionAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    requestCheckAreaEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    requestBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000001' // 요청중 상태
        && this.popupParam.sopImprovementId // key가 있는 경우, 저장이 한번 된 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    receiptBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    requestCancelBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000005' // 조치부서 접수중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    actionCompleteBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    rejectBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    completeBtnEditable() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
    tempSaveBtnEditable() {
      return this.imprEditable
        && this.popupParam.data && !this.popupParam.sopImprovementId
    },
    saveBtnEditable1() {
      return this.imprEditable
        && ((this.impr.ibmStepCd === 'IS00000001' // 요청중 and 로그인자의 부서가 요청부서
          && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd)
        || (this.impr.ibmStepCd === 'IS00000005'
         && this.impr.actionDeptCd === this.$store.getters.user.deptCd)) // 조치부서 접수중 and 조치부서인 경우
    },
    saveBtnEditable2() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000010' // 조치부서 조치중 상태
        && this.impr.actionDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 조치부서인 경우
    },
    saveBtnEditable3() {
      return this.imprEditable
        && this.impr.ibmStepCd === 'IS00000015' // 요청부서 검토중 상태
        && this.impr.improveRequestDeptCd === this.$store.getters.user.deptCd // 로그인자의 부서가 요청부서인 경우
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.get.url;
      this.saveUrl = transactionConfig.sop.ibm.improve.update.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$_.extend(this.impr, this.popupParam)
    },
    /**
     * 요청&접수 상세 저장
     */
    saveRequestReceiptImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId

              this.isSaveRequestReceipt = !this.isSaveRequestReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveRequestReceiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 요청
     */
    requestImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치부서에 개선요청하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000005';

              this.isRequest = !this.isRequest
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    requestCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 접수
     */
    receiptImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '요청건을 접수하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000010';

              this.isReceipt = !this.isReceipt
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    receiptCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 요청취소
     */
    rejectImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          if (this.impr.requestRejectReason) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '요청취소하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.impr.chgUserId = this.$store.getters.user.userId;
                this.impr.ibmStepCd = 'IS00000001';

                this.isReject = !this.isReject
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('ALERT', {
              title: '안내', // 안내
              message: '요청취소사유를 입력하세요.', // 요청취소사유를 입력하세요.
              type: 'warning', // success / info / warning / error
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    rejectCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치완료
     */
    actionCompleteImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000015';

              this.isActionComplete = !this.isActionComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    actionCompleteCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveActionImpr() {
      this.$refs['editForm2'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;

              this.isSaveAction = !this.isSaveAction
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveActionCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치 저장
     */
    saveCheckImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;

              this.isSaveCheck = !this.isSaveCheck
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCheckCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 개선완료
     */
    completeImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '개선 완료처리하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS00000020';

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 조치반려
     */
    returnImpr() {
      this.$refs['editForm3'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '조치반려하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId;
              this.impr.ibmStepCd = 'IS0000010';

              this.isReturn = !this.isReturn
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    returnCallback(result) {
      this.popupParam.sopImprovementId = result.data
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>